import * as THREE from 'three';
import ParticleGeometry from './ParticleGeometry';
import ParticleMaterial from './ParticleMaterial';
import LineGeometry from './LineGeometry';
import LineMaterial from './LineMaterial';
import ConnectionManager from './ConnectionManager';

const FloatingParticles = {
  create: (scene, options = {}) => {
    const {
      count = 500,
      maxConnections = 3,
      connectionDistance = 4,
      fadeDuration = 2,
      recalculateInterval = 2 // Interval for full recalculation
    } = options;

    const geometry = ParticleGeometry.create(options);
    const material = ParticleMaterial.create();
    const particleSystem = new THREE.Points(geometry, material);
    scene.add(particleSystem);

    const lineMaterial = LineMaterial.create();
    const lineGeometry = LineGeometry.create();
    const lineMesh = new THREE.LineSegments(lineGeometry, lineMaterial);
    scene.add(lineMesh);

    // Create a special mouse cursor particle (red dot)
    const cursorParticleGeometry = new THREE.BufferGeometry();
    const cursorParticlePositions = new Float32Array(3); // x, y, z
    cursorParticleGeometry.setAttribute('position', new THREE.BufferAttribute(cursorParticlePositions, 3));

    // Create cursor particle with fixed size
    const cursorParticleMaterial = new THREE.PointsMaterial({
      color: 0xff0000,  // Red color
      size: 0.0,  // Fixed size for the cursor particle
      transparent: true,
      opacity: 1.0,
      depthTest: false,  // Ensure it always renders on top
      depthWrite: false,
      sizeAttenuation: true  // Use size attenuation for proper sizing on camera
    });

    const cursorParticle = new THREE.Points(cursorParticleGeometry, cursorParticleMaterial);
    cursorParticle.visible = false; // Initially hidden until mouse moves
    scene.add(cursorParticle);

    // Create a line to connect cursor to closest particle
    const cursorLineGeometry = new THREE.BufferGeometry();
    const cursorLinePositions = new Float32Array(6); // Two points, each with x, y, z
    cursorLineGeometry.setAttribute('position', new THREE.BufferAttribute(cursorLinePositions, 3));
    
    // Ensure color attribute is initialized
    const cursorLineColors = new Float32Array(8); // Two points, each with RGBA
    cursorLineGeometry.setAttribute('color', new THREE.BufferAttribute(cursorLineColors, 4));

    // Use vertexColors for consistency with main lines
    const cursorLineMaterial = new THREE.LineBasicMaterial({
      vertexColors: true,
      transparent: true,
      linewidth: 2
    });

    const cursorLine = new THREE.Line(cursorLineGeometry, cursorLineMaterial);
    cursorLine.visible = false; // Initially hidden
    scene.add(cursorLine);

    // Initialize the navbar height
    ConnectionManager.updateNavbarHeight();

    return {
      particleSystem,
      geometry,
      material,
      lineMesh,
      lineGeometry,
      lineMaterial,
      maxConnections,
      connectionDistance,
      fadeDuration,
      recalculateInterval,
      activeConnections: [],
      connectionOpacities: [],
      connectionTargets: [],
      lastRecalculateTime: 0,
      camera: null, // Will be set by the update method
      animatedPositions: [], // Will store animated positions
      cursorParticle,
      cursorParticleGeometry,
      cursorParticleMaterial,
      cursorLine,
      cursorLineGeometry,
      cursorLineMaterial,
      mousePosition: new THREE.Vector3(), // Store mouse position in world space
      closestParticleIndex: -1 // Index of closest particle to cursor
    };
  },

  update: (particles, props = {}) => {
    if (!particles || !particles.material) return;
  
    const { delta = 0.016, time = 0, camera, mouse } = props;
  
    // Store camera reference
    if (camera) {
      particles.camera = camera;
    }
  
    // Update shader time
    particles.material.uniforms.time.value += delta;
  
    // Update navbar clipping (if using that feature)
    const navbar = document.querySelector('.navbar');
    if (navbar && particles.lineMaterial && 
        particles.lineMaterial.uniforms && 
        particles.lineMaterial.uniforms.uNavbarClipY !== undefined) {
      const navbarY = navbar.getBoundingClientRect().bottom;
      const navbarClipY = 1 - (navbarY / window.innerHeight) * 2; // Convert to clip space
      particles.lineMaterial.uniforms.uNavbarClipY.value = navbarClipY;
    }
  
    // Update mouse cursor position
    if (mouse && camera) {
      ConnectionManager.setMousePosition(
        (mouse.x + 1) * 0.5 * window.innerWidth,
        (1 - mouse.y) * 0.5 * window.innerHeight,
        camera,
        particles
      );
    } else if (particles.cursorParticle) {
      particles.cursorParticle.visible = false;
      particles.cursorLine.visible = false;
    }
  
    // Periodically recalculate all connections
    if (time - particles.lastRecalculateTime > particles.recalculateInterval) {
      particles.lastRecalculateTime = time;
      ConnectionManager.recalculateConnections(particles);
    } else {
      // Update line opacities every frame
      ConnectionManager.updateLineOpacity(particles, delta);
    }
  },

  dispose: (particles, scene) => {
    if (!particles) return;

    scene.remove(particles.particleSystem);
    scene.remove(particles.lineMesh);

    // Also remove cursor particle and line
    if (particles.cursorParticle) {
      scene.remove(particles.cursorParticle);
      particles.cursorParticleGeometry.dispose();
      particles.cursorParticleMaterial.dispose();
    }

    if (particles.cursorLine) {
      scene.remove(particles.cursorLine);
      particles.cursorLineGeometry.dispose();
      particles.cursorLineMaterial.dispose();
    }

    particles.geometry.dispose();
    particles.material.dispose();
    particles.lineGeometry.dispose();
    particles.lineMaterial.dispose();
  }
};

export default FloatingParticles;