import React from 'react';
import './About.css';

const About = () => {
  return (
    <section className="about-container">
      <h2 className="section-title">About</h2>

      <div className="about-content">
        <div className="about-hologram-card">
          <img src="/images/Profile.png" alt="Maxwell Brickel" className="about-image" />
        </div>

        <p className="about-summary">
          I'm a full-stack software engineer with a decade of experience in game development and tools,  
          specializing in crafting unique and engaging player experiences.  
          My expertise spans everything from building immersive slot machine games to developing mobile applications like  
          Prize Kingdoms, which achieved hundreds of thousands of downloads and retained thousands of daily active users.
          <br></br><br></br>

          <ul className="about-list">
            <strong>Professional accomplishments include:</strong>

            <li><strong>Development of <em>Prize Kingdoms</em></strong> - a mobile game that reached a massive audience and fostered an active player base.</li>
            <li><strong>Release of numerous slot machine titles</strong> that power real-world casino floors.</li>
            <li><strong>Enhancing game features & live ops tools</strong> to improve player experiences and streamline development workflows.</li>
          </ul>

          <br></br>
          Whether working solo or collaborating with a team, I thrive on pushing creative and technical boundaries  
          to build innovative solutions.  
          When I'm not coding, you'll find me gaming or spending quality time with my family.

        </p>

      </div>
    </section>
  );
};

export default About;
