import React from 'react';

const ScrollToBottomButton = ({ children, className, containerSelector = '.app-container' }) => {
  const scrollToBottom = (event) => {
    event.preventDefault(); // Prevent default link behavior

    const container = document.querySelector(containerSelector) || document.body;
    const containerHeight = container.scrollHeight;
    const viewportHeight = container.clientHeight;
    const maxScroll = containerHeight - viewportHeight;

    if (maxScroll > 0) {
      const lastSection = container.querySelector('.content-sections > div:last-child');
      if (lastSection) {
        container.scrollTo({ top: lastSection.offsetTop, behavior: 'smooth' });
      } else {
        container.scrollTo({ top: maxScroll, behavior: 'smooth' });
      }
    }
  };

  return (
    <a href="#contact" className={`navbar-button ${className}`} onClick={scrollToBottom}>
      {children}
    </a>
  );
};

export default ScrollToBottomButton;
