import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import SphereObject from "./Components/Model/SphereObject";
import ModelObject from "./Components/Model/ModelObject";
import AlternateModelObject from "./Components/Model/AlternateModelObject";
import FluidBackground from "./Components/Background/FluidBackground";
import FloatingParticles from "./Components/FloatingParticles/FloatingParticles";
import ContentSections from "./Components/ContentSections/ContentSections";
import "./App.css";
import { FaLinkedin } from "react-icons/fa";
import Typewriter from "./Components/Text/Typewriter";
import SkillShowcase from "./Components/ContentSections/SkillShowcase/SkillShowcase";
import About from "./Components/ContentSections/About/About";
import ScrollToBottomButton from "./Components/Buttons/ScrollToBottomButton";
import Footer from "./Components/Footer/Footer";
import Contact from "./Components/ContentSections/Contact/Contact";
import Experience from "./Components/ContentSections/Experience/Experience";
import ProjectShowcase from "./Components/ContentSections/ProjectShowcase/ProjectShowcase";

function App() {
  const pointer = useRef({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(
    new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  );
  const rendererRef = useRef(new THREE.WebGLRenderer({ antialias: true, alpha: true }));

  const sphereRef = useRef(null);
  const modelRef = useRef(null);
  const altModelRef = useRef(null);
  const fluidRef = useRef(null);
  const particlesRef = useRef(null);
  const animationFrameId = useRef(null);

  const mouse = useRef({ x: 0, y: 0 });
  const mouseScreenPos = useRef({ x: 0, y: 0 });
  const isTouching = useRef(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);

  const overlayText = "Hello, I'm Max!\nI am a software engineer & game developer.";
  const typingDelay = 50;

  const sections = [
    { id: "about", content: <About /> },
    { id: "projectShowcase", content: <ProjectShowcase /> },
    { id: "experience", content: <Experience /> },
    { id: "skillShowcase", content: <SkillShowcase /> },
    { id: "contact", content: <Contact /> },
  ];

  // Calculate navbar clipping Y position
  const calculateNavbarClipY = () => {
    const navbar = document.querySelector(".navbar");
    return navbar ? navbar.getBoundingClientRect().top / window.innerHeight : 0;
  };

  // Scene Initialization
  useEffect(() => {
    cameraRef.current.position.z = 10;
    rendererRef.current.setSize(window.innerWidth, window.innerHeight);
    rendererRef.current.setClearColor(0x000000, 0);

    if (containerRef.current) {
      containerRef.current.appendChild(rendererRef.current.domElement);
    }

    // Initialize objects
    sphereRef.current = SphereObject.create(sceneRef.current);
    modelRef.current = modelRef.current || ModelObject.create(sceneRef.current);
    altModelRef.current = altModelRef.current || AlternateModelObject.create(sceneRef.current);
    fluidRef.current = FluidBackground.create(sceneRef.current);

    if(!particlesRef.current)
    {
      particlesRef.current = FloatingParticles.create(sceneRef.current, {
        camera: cameraRef.current,
        count: 500,
        colorBase: { h: 0.6, s: 0.8, l: 0.5 },
        colorVariation: 0.1,
        mouseInfluence: { radius: 5, strength: 0.5 },
        maxConnections: 3,
        connectionDistance: 4,
        enableMouseInteraction: true,
      });
    }

    const animate = () => {
      const delta = 0.016;
      const time = performance.now() / 1000;

      if (sphereRef.current) SphereObject.update(sphereRef.current, { delta, mouse: mouse.current, scrollProgress });
      if (modelRef.current) ModelObject.update(modelRef.current, { delta, mouse: mouse.current, scrollProgress, navbarClipY: calculateNavbarClipY() });
      if (altModelRef.current) AlternateModelObject.update(altModelRef.current, { delta, mouse: mouse.current, scrollProgress, navbarClipY: calculateNavbarClipY() });
      if (fluidRef.current) FluidBackground.update(fluidRef.current, { delta, mouse: mouse.current, scrollY: scrollPosition });
      if (particlesRef.current) FloatingParticles.update(particlesRef.current, { delta, time, mouse: mouse.current, camera: cameraRef.current });

      rendererRef.current?.render(sceneRef.current, cameraRef.current);
      animationFrameId.current = requestAnimationFrame(animate);
    };

    animationFrameId.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId.current);
      disposeObjects();
    };
  }, []);

  // Common function to update mouse position from any pointer event
  const updateMousePosition = (x, y) => {
    mouse.current = {
      x: (x / window.innerWidth) * 2 - 1,
      y: -(y / window.innerHeight) * 2 + 1,
    };
    mouseScreenPos.current = { x, y };

    if (particlesRef.current?.connectionManager) {
      particlesRef.current.connectionManager.setMousePosition(x, y, cameraRef.current, particlesRef.current);
    }
  };

  // Handle mouse movement
  useEffect(() => {
    const onMouseMove = (event) => {
      if (isTouching.current) return; // Skip if currently touching (to avoid conflicts)
      updateMousePosition(event.clientX, event.clientY);
    };

    const onMouseLeave = () => {
      if (isTouching.current) return; // Skip if currently touching
      particlesRef.current?.cursorParticle && (particlesRef.current.cursorParticle.visible = false);
      particlesRef.current?.cursorLine && (particlesRef.current.cursorLine.visible = false);
      particlesRef.current?.connectionManager?.disableMouseConnections();
    };

    // Touch event handlers
    const onTouchStart = (event) => {
      isTouching.current = true;
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        updateMousePosition(touch.clientX, touch.clientY);
      }
    };

    const onTouchMove = (event) => {
      if (event.touches.length > 0) {
        const touch = event.touches[0];
        updateMousePosition(touch.clientX, touch.clientY);
      }
    };

    const onTouchEnd = () => {
      isTouching.current = false;
      particlesRef.current?.cursorParticle && (particlesRef.current.cursorParticle.visible = false);
      particlesRef.current?.cursorLine && (particlesRef.current.cursorLine.visible = false);
      particlesRef.current?.connectionManager?.disableMouseConnections();
    };

    // Add mouse event listeners
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseleave", onMouseLeave);
    
    // Add touch event listeners
    window.addEventListener("touchstart", onTouchStart, { passive: true });
    window.addEventListener("touchmove", onTouchMove, { passive: true });
    window.addEventListener("touchend", onTouchEnd);
    window.addEventListener("touchcancel", onTouchEnd);

    return () => {
      // Remove mouse event listeners
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseleave", onMouseLeave);
      
      // Remove touch event listeners
      window.removeEventListener("touchstart", onTouchStart);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", onTouchEnd);
      window.removeEventListener("touchcancel", onTouchEnd);
    };
  }, []);

  // Handle scrolling
  useEffect(() => {
    const handleScroll = () => {
      const container = document.querySelector(".app-container");
      if (!container) return;

      setScrollPosition(container.scrollTop);
      setScrollProgress((container.scrollTop / (container.scrollHeight - container.clientHeight)) || 0);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const aspectRatio = width / height;
  
      // Update the camera's aspect ratio
      cameraRef.current.aspect = aspectRatio;
  
      // Detect if the user is on a mobile device
      const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

      // Set FOV based on device type
      const baseFOV = 60;
      cameraRef.current.fov = isMobile
        ? baseFOV * (1 + (1 - aspectRatio) * 0.32) // Mobile Adaptive FOV
        : 75; // Desktop Fixed FOV
  
      // Ensure the camera's projection matrix is updated after changes
      cameraRef.current.updateProjectionMatrix();
  
      // Update the renderer size
      rendererRef.current.setSize(width, height);
      rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio, 2)); // Cap pixel ratio for performance
  
      // Adjust the camera position based on the viewport size
      const distanceMultiplier = 1 + Math.max(0, (1800 - Math.min(width, height)) / 1000);
      cameraRef.current.position.z = 5 * distanceMultiplier; // Adjust base distance (5) as needed
  
      // Resize fluid background
      if (fluidRef.current) {
        FluidBackground.resize(fluidRef.current);
      }
    };
  
    window.addEventListener("resize", handleResize);

    handleResize();
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Dispose objects when unmounting
  const disposeObjects = () => {
    [sphereRef, modelRef, altModelRef, fluidRef, particlesRef].forEach((ref) => {
      if (ref.current) {
        ref.current.dispose?.(sceneRef.current);
        sceneRef.current.remove(ref.current);
      }
    });
  };

  return (
    <div className="app-container">
      <div ref={containerRef} className="three-canvas"></div>
      <div className="text-overlay">
        <Typewriter text={overlayText} delay={typingDelay} fontSize="100px" showCursor hideCursorOnComplete />
      </div>
      <div className="navbar">
        <a href="/Resume_2025.pdf" download="Max_CV.pdf">Download CV</a>
        <ScrollToBottomButton className="">Contact</ScrollToBottomButton>
        <a 
          href="https://www.linkedin.com/in/maxwellbrickel/" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="LinkedIn Profile"
        >
          <FaLinkedin className="social-icon" />
        </a>
      </div>
      <ContentSections sections={sections} />
      <Footer />
    </div>
  );
}

export default App;