import * as THREE from "three";

const FluidBackground = {
  create: (scene) => {
    // Create initial plane geometry based on screen size
    const planeGeometry = new THREE.PlaneGeometry(window.innerWidth, window.innerHeight, 1, 1);

    // Vertex Shader
    const vertexShader = `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `;

    // Fragment Shader with Fixed Light Size
    const fragmentShader = `
      uniform float time;
      uniform vec2 resolution;
      uniform vec2 mouse;
      uniform float scrollY;
      varying vec2 vUv;
      
      float hash(float n) { return fract(sin(n) * 1e4); }
      float noise(vec2 x) {
        vec2 i = floor(x);
        vec2 f = fract(x);
        float a = hash(i.x + i.y * 57.0);
        float b = hash(i.x + 1.0 + i.y * 57.0);
        float c = hash(i.x + (i.y + 1.0) * 57.0);
        float d = hash(i.x + 1.0 + (i.y + 1.0) * 57.0);
        vec2 u = f * f * (3.0 - 2.0 * f);
        return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
      }
      
      float fbm(vec2 x) {
        float v = 0.0;
        float a = 0.5;
        for (int i = 0; i < 5; ++i) {
          v += a * noise(x);
          x = x * 2.0 + vec2(100.0);
          a *= 0.5;
        }
        return v;
      }

      void main() {
        // Normalize UV coordinates so light remains consistent
        vec2 aspectRatio = vec2(resolution.x / resolution.y, 1.0);
        vec2 uv = vUv * aspectRatio;

        // Create the fluid effect
        float timeOffset = time * 0.15;
        vec2 q = vec2(fbm(uv + timeOffset), fbm(uv - timeOffset));
        vec2 r = vec2(fbm(uv + q + 1.0), fbm(uv + q - 1.0));

        float f = fbm(uv + r + timeOffset * 0.1);

        // Define colors
        vec3 baseColor = vec3(0.05, 0.05, 0.1); // Dark
        vec3 midColor = vec3(0.3, 0.3, 0.5);   // Mid tones
        vec3 highlightColor = vec3(0.6, 0.6, 0.7);   // Light

        vec3 finalColor = mix(baseColor, midColor, clamp(f * f * 4.0, 0.0, 1.0));
        finalColor = mix(finalColor, highlightColor, clamp(length(q), 0.0, 1.0));

        // Keep light size consistent across different screen sizes
        float mouseRadius = 0.15; // Fixed light size
        vec2 mouseEffect = (mouse / resolution) * 2.0 - 1.0; // Normalize mouse to [-1,1]
        float mouseDist = length(uv - mouseEffect);
        finalColor += vec3(0.3, 0.3, 0.5) * smoothstep(mouseRadius, 0.0, mouseDist);

        // Add subtle vignette
        float vignette = smoothstep(0.3, 1.0, length(vUv - 0.5) * 1.5);
        finalColor *= 1.0 - vignette;

        gl_FragColor = vec4(finalColor, 0.5);
      }
    `;

    // Create the shader material
    const fluidMaterial = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
        mouse: { value: new THREE.Vector2(0, 0) },
        scrollY: { value: 0 },
      },
      vertexShader,
      fragmentShader,
      transparent: true,
      depthWrite: false,
    });

    // Create the mesh and position it behind everything
    const fluidMesh = new THREE.Mesh(planeGeometry, fluidMaterial);
    fluidMesh.position.z = -5;
    scene.add(fluidMesh);

    return fluidMesh;
  },

  update: (fluidMesh, { delta, mouse, scrollY }) => {
    if (fluidMesh && fluidMesh.material.uniforms) {
      // Update time uniform
      fluidMesh.material.uniforms.time.value += delta;

      // Update mouse position smoothly
      fluidMesh.material.uniforms.mouse.value.lerp(mouse, 0.1);

      // Update scroll position
      fluidMesh.material.uniforms.scrollY.value = scrollY;
    }
  },

  resize: (fluidMesh) => {
    if (!fluidMesh) return;

    const width = window.innerWidth;
    const height = window.innerHeight;

    // Update plane geometry dynamically
    const newGeometry = new THREE.PlaneGeometry(width, height, 1, 1);
    fluidMesh.geometry.dispose();
    fluidMesh.geometry = newGeometry;

    // Update shader resolution uniform
    if (fluidMesh.material.uniforms.resolution) {
      fluidMesh.material.uniforms.resolution.value.set(width, height);
    }
  },

  dispose: (fluidMesh, scene) => {
    if (fluidMesh) {
      fluidMesh.geometry.dispose();
      fluidMesh.material.dispose();
      scene.remove(fluidMesh);
    }
  },
};

export default FluidBackground;
