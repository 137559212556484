import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { FaReact, FaNodeJs, FaJsSquare, FaUnity, FaDocker, FaGitAlt, FaHtml5, FaCss3Alt } from "react-icons/fa";
import { SiVuedotjs , SiTypescript, SiMongodb, SiMysql } from "react-icons/si";
import "./SkillShowcase.css";

const skills = [
  { name: "React", icon: <FaReact size={40} color="#61DAFB" />, desc: "Frontend" },
  { name: "Vue.js", icon: <SiVuedotjs  size={40} color="#4FC08D" />, desc: "Frontend" },
  { name: "Node.js", icon: <FaNodeJs size={40} color="#68A063" />, desc: "Backend" },
  { name: "JavaScript", icon: <FaJsSquare size={40} color="#F7DF1E" />, desc: "Webdev & Tools" },
  { name: "TypeScript", icon: <SiTypescript size={40} color="#3178C6" />, desc: "Webdev & Tools" },
  { name: "Unity", icon: <FaUnity size={40} color="#000000" />, desc: "Game Development" },
  { name: "C#", icon: <img src="/images/CSharpIcon.png" className="icon-img" alt="Perforce" />, desc: "Game Development, Tools, & Backend" },
  { name: "SQL", icon: <img src="/images/SQLIcon.png" className="icon-img" alt="Perforce" />, desc: "Database management" },
  { name: "MongoDB", icon: <SiMongodb size={40} color="#47A248" />, desc: "NoSQL database management" },
  { name: "Docker", icon: <FaDocker size={40} color="#2496ED" />, desc: "Containerization" },
  { name: "Git", icon: <FaGitAlt size={40} color="#F05032" />, desc: "Version control" },
  { name: "HTML", icon: <FaHtml5 size={40} color="#E34F26" />, desc: "Webdev" },
  { name: "CSS", icon: <FaCss3Alt size={40} color="#1572B6" />, desc: "Webdev" },
];

const SkillShowcase = () => {
  const trackRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const track = trackRef.current;
    const container = containerRef.current;
    if (track && container) {
      const trackWidth = track.scrollWidth;
      const animationDuration = 16;

      const keyframes = `@keyframes scroll {
        0% { transform: translateX(0); }
        100% { transform: translateX(-${trackWidth / 2}px); }
      }`;

      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerHTML = keyframes;
      document.head.appendChild(styleSheet);

      track.style.animation = `scroll ${animationDuration}s linear infinite`;
    }

    return () => {
      // cleanup
    };
  }, []);

  return (
    <div className="skills-container" ref={containerRef}>
      <h2 className="section-title">Tech Stack</h2> {/* Added title */}
      <div className="skills-track" ref={trackRef}>
        {[...skills, ...skills].map((skill, index) => (
          <motion.div key={index} className="skill-card">
            <div className="skill-icon">{skill.icon}</div>
            <h3 className="skill-name">{skill.name}</h3>
            <p className="skill-desc">{skill.desc}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default SkillShowcase;