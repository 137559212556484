import * as THREE from 'three';

const ParticleGeometry = {
  create: ({
    count = 500,
    colorBase = { h: 0.6, s: 0.8, l: 0.5 },
    colorVariation = 0.1,
    size = { min: 0.1, max: 0.6 },
    spread = { x: 40, y: 40, z: 10 }
  } = {}) => {
    const geometry = new THREE.BufferGeometry();

    // Allocate memory for the arrays
    const positions = new Float32Array(count * 3);
    const sizes = new Float32Array(count);
    const colors = new Float32Array(count * 3);

    // Populate arrays with random values
    for (let i = 0; i < count; i++) {
      // Position
      positions.set([
        (Math.random() - 0.5) * spread.x,
        (Math.random() - 0.5) * spread.y,
        (Math.random() - 0.5) * spread.z - 2
      ], i * 3);

      // Size
      sizes[i] = Math.random() * (size.max - size.min) + size.min;

      // Color (HSL to RGB)
      const color = new THREE.Color();
      const h = colorBase.h + (Math.random() - 0.5) * colorVariation;
      color.setHSL(h, colorBase.s, colorBase.l);
      colors.set([color.r, color.g, color.b], i * 3);
    }

    // Set the attributes for the geometry
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

    return geometry;
  }
};

export default ParticleGeometry;
