import React, { useState, useEffect } from 'react';
import './Typewriter.css';

const Typewriter = ({ 
  text, 
  delay = 100, 
  showCursor = true, 
  hideCursorOnComplete = false 
}) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex >= text.length) return; // Stop when text is fully typed

    const timer = setTimeout(() => {
      setDisplayText(text.slice(0, currentIndex + 1)); // Slice avoids string concatenation
      setCurrentIndex(prev => prev + 1);
    }, delay);

    return () => clearTimeout(timer);
  }, [currentIndex, text, delay]);

  const shouldShowCursor = showCursor && !(hideCursorOnComplete && currentIndex >= text.length);

  return (
    <div className="typewriter-container">
      <span className="typewriter-text">{displayText}</span>
      {shouldShowCursor && <span className="cursor">|</span>}
    </div>
  );
};

export default Typewriter;
