import React from "react";
import { FaLinkedin } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-links">

        {/* <a href="https://github.com/spacecomplexity" target="_blank" rel="noopener noreferrer">
          <FaGithub className="social-icon" />
        </a> */}
      </div>
      <p>© {currentYear} Maxwell Brickel. All rights reserved.</p>
    </footer>
  );
};

export default Footer;