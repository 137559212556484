import React from "react";
import { motion } from "framer-motion";
import "./Experience.css";

const experiences = [
  {
    company: "INK Games",
    role: "Software Engineer",
    duration: "2021 - 2023",
    description: `
      Contributed to the development of gameplay features and tools for 
      <a href='https://play.google.com/store/apps/details?id=com.inkgames.prizekingdoms&hl=en_US&pli=1' 
      target='_blank' rel='noopener noreferrer'>Prize Kingdoms</a>, 
      a mobile game where players win real-world prizes via sweepstakes tickets.
      
      <br></br>
        Languages used: C#, Typescript
      <br></br>

      <ul>
        <li>
          Developed player-facing game features (some examples 
          include traps, bonuses, special tiles, features sweepstakes,  
          and club chat) and backend tools for the mobile game Prize 
          Kingdoms, enhancing user engagement and system 
          performance
        </li>
        <li>
          Worked on key features in the admin tool that empowered 
          non-technical teams to manage live data. 
        </li>
        <li>
          Built the dynamic, scalable club chat system front-end, 
          improving real-time player engagement and retention 
        </li>
        <li>
          Created and optimized reusable front-end components, 
          reducing admin tool feature  
        </li>
      </ui>
    `,
  },
  {
    company: "LACC Technologies LLC",
    role: "Software Engineer",
    duration: "2020 - 2021",
    description: `
    Sole Unity client developer on a small team making slot machine games and a platform to run them on.

    <br></br>
      Languages used: C#
    <br></br>

    <ul>
      <li>
        Designed and implemented systems for data visualization, user interaction, and reward management
      </li>
      <li>
        Developed a request management system for external API communications 
      </li>
      <li>
        Built automated data parsing tools, improving data accuracy and workflow efficiency  
      </li>
      <li>
        Led a major platform migration from Godot to Unity
      </li>
    </ul>
    `,
  },
  {
    company: "Everi",
    role: "Game Developer I",
    duration: "2015 - 2019",
    description: `
      Solo gameplay programmer for various slot machine titles, some still found in casinos.  
      Notable games:  
      <a href='https://www.youtube.com/watch?v=6la5adRYREg' target='_blank' rel='noopener noreferrer'>Lucky Totem</a>,  
      <a href='https://youtu.be/R3KLazcx82A?t=297' target='_blank' rel='noopener noreferrer'>Electric/Blazin' Triple Wheel</a>,  
      <a href='https://youtu.be/nem7wlvme14?t=428' target='_blank' rel='noopener noreferrer'>Tut's Reign</a>.

      <br></br>
        Languages used: Javascript
      <br></br>

      <ul>
        <li>
          Developed multiple slot machine titles and core software library features, improving product consistency 
        </li>
        <li>
          Implemented a 3D animation system for interactive visual elements, differentiating products in the market 
        </li>
        <li>
          Created a deployment automation tool to reduce test cycles and improve test build frequency 
        </li>
      </ul>

    `,
  },
];

const Experience = () => {
  return (
    <section className="experience-container">
      <h2 className="section-title">Experience</h2>
      <div className="timeline">
        {experiences.map((exp, index) => (
          <div
             key={exp.company}
             className="timeline-item"
            // initial={{ opacity: 0, x: -50 }}
            // whileInView={{ opacity: 1, x: 0 }}
            // viewport={{ once: true, amount: 0.3 }}
            // transition={{ duration: 0.6, delay: index * 0.2 }}
          >
            <motion.div
              className="timeline-marker"
              initial={{ scale: 0.5, opacity: 0 }}
              whileInView={{ scale: 1.2, opacity: 1 }}
              animate={{ scale: [1.2, 1, 1.2], opacity: [1, 0.8, 1] }}
              transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
            />

            {index === 0 ? (
              <motion.div
                className="timeline-line"
                initial={{ height: 0, top: "50%" }}
                whileInView={{ height: "50%", top: "50%" }}
                viewport={{ once: true }}
                transition={{ duration: 1, ease: "easeInOut" }}
              ></motion.div>
            ) : index === experiences.length - 1 ? (
              <motion.div
                className="timeline-line"
                initial={{ height: 0, top: "-50%" }}
                whileInView={{ height: "50%", top: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 1, ease: "easeInOut" }}
              ></motion.div>
            ) : (
              index !== experiences.length - 1 && (
                <motion.div
                  className="timeline-line"
                  initial={{ height: 0, top: 0 }}
                  whileInView={{ height: "100%", top: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, ease: "easeInOut" }}
                ></motion.div>
              )
            )}

            <div className="timeline-content">
              <h3>{exp.company}</h3>
              <p className="role">{exp.role} <span>({exp.duration})</span></p>
              <p className="description" dangerouslySetInnerHTML={{ __html: exp.description }} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience;
