import React, { useState, useCallback } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const validate = useCallback(() => {
    const newErrors = {};

    if (formData.name.trim().length < 2) newErrors.name = "Name must be at least 2 characters.";
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) newErrors.email = "Enter a valid email address.";
    if (!formData.message.trim()) newErrors.message = "Message cannot be empty.";
    else if (formData.message.length > 500) newErrors.message = "Message cannot exceed 500 characters.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (!validate()) return;

    emailjs
      .sendForm("service_y7sursf", "template_t00b7wn", e.target, "MI31bd6jN_dZ-rw5O")
      .then(() => {
        setSuccessMessage("Your message has been sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      })
      .catch(() => setSuccessMessage("Failed to send message. Please try again."));

    setTimeout(() => setSuccessMessage(""), 5000);
  }, [validate]);

  return (
    <section className="contact-container">
      <h2 className="section-title">Contact</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        {["name", "email", "message"].map((field) => (
          <div key={field} className="form-group">
            <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
            {field === "message" ? (
              <textarea id={field} name={field} value={formData[field]} onChange={handleChange} placeholder="Write your message..." maxLength="500" />
            ) : (
              <input id={field} type={field === "email" ? "email" : "text"} name={field} value={formData[field]} onChange={handleChange} placeholder={`Enter your ${field}`} />
            )}
            {errors[field] && <p className="error">{errors[field]}</p>}
          </div>
        ))}
        <button type="submit" className="submit-button">Send Message</button>
        {successMessage && <p className="success-message">{successMessage}</p>}
      </form>
    </section>
  );
};

export default Contact;
