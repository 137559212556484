import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from 'three';

const AlternateModelObject = {
    model: null,

    create: (scene) => {
        const loader = new GLTFLoader();
        loader.load("/models/headshot.glb", (gltf) => {
            const model = gltf.scene;
            model.scale.set(1.5, 1.5, 1.5);

            // Shader Code
            const vertexShader = `
                varying vec4 vClipPosition;
                varying vec3 vNormal;
                varying vec3 vPosition;
                varying vec2 vUv;

                void main() {
                    vClipPosition = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    vNormal = normalize(normalMatrix * normal);
                    vPosition = (modelMatrix * vec4(position, 1.0)).xyz;
                    vUv = uv; // Pass texture UV coordinates to fragment shader
                    gl_Position = vClipPosition;
                }
            `;

            const fragmentShader = `
                uniform float uNavbarClipY;
                uniform sampler2D uTexture; // Texture sampler

                varying vec4 vClipPosition;
                varying vec2 vUv;

                void main() {
                    float clipY = vClipPosition.y / vClipPosition.w;
                    
                    // Convert uNavbarClipY from screen space (0 to 1) to clip space (-1 to 1)
                    float navbarInClipSpace = 1.0 - 2.0 * uNavbarClipY;
                    
                    if (clipY < navbarInClipSpace) discard;

                    // Sample the texture directly (NO color modifications)
                    vec4 texColor = texture2D(uTexture, vUv);

                    // Preserve texture color and transparency
                    gl_FragColor = texColor;
                }
            `;

            model.traverse((child) => {
                if (child.isMesh && child.material.map) {
                    // Create Shader Material with the texture
                    const material = new THREE.ShaderMaterial({
                        uniforms: {
                            uNavbarClipY: { value: 0.0 },
                            uTexture: { value: child.material.map }, // Assign the original texture
                        },
                        vertexShader,
                        fragmentShader,
                        transparent: true,
                    });

                    child.material = material;
                }
                
                child.castShadow = true;
                child.receiveShadow = true;
            });

            scene.add(model);
            AlternateModelObject.model = model;
        });

        return {
            update: ({ delta, mouse, scrollProgress, navbarClipY }) => {
                if (AlternateModelObject.model) {
                    const model = AlternateModelObject.model;

                    const angle = scrollProgress * Math.PI * 2;
                    const radius = 3;
                    const targetX = Math.sin(angle) * radius;
                    const targetY = Math.cos(angle) * radius;

                    model.position.x += (targetX - model.position.x) * 0.05;
                    model.position.y += (targetY - model.position.y) * 0.05;

                    const scale = 0.8 + Math.sin(scrollProgress * Math.PI) * 0.3;
                    const scaleMultiplier = 1.5;
                    const newScale = scale * scaleMultiplier;
                    model.scale.set(newScale, newScale, newScale);

                    model.rotation.x = 19.25;//20 + scrollProgress * Math.PI * 2;
                    model.rotation.y = scrollProgress * Math.PI * 4;

                    // Detect if the user is on a mobile device and adjust sensitivity accordingly
                    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
                    const mouseInfluence = isMobile ? 0.3 : 0.2; // Slightly stronger influence on mobile
                    
                    model.position.x += mouse.x * mouseInfluence;
                    model.position.y += mouse.y * mouseInfluence;

                    // Update uniform
                    model.traverse((child) => {
                        if (child.isMesh && child.material.uniforms) {
                            child.material.uniforms.uNavbarClipY.value = navbarClipY;
                        }
                    });
                }
            },
        };
    },

    update: (controller, params) => {
        if (controller && typeof controller.update === 'function') {
            controller.update(params);
        }
    },

    dispose: (controller, scene) => {
        if (controller && controller.model) {
            controller.model.traverse((child) => {
                if (child.isMesh) {
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                }
            });
            scene.remove(controller.model);
            controller.model = null;
            controller.isLoaded = false;
        }
    }
};

export default AlternateModelObject;