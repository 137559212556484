import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from 'three';

const ModelObject = {
    model: null,

    create: (scene) => {
        const loader = new GLTFLoader();
        loader.load("/models/headshot.glb", (gltf) => {
            const model = gltf.scene;
            model.scale.set(3, 3, 3);
            model.position.set(0, 0, 0);

            // Extract the texture from the GLTF model
            let texture = null;
            model.traverse((child) => {
                if (child.isMesh && child.material.map) {
                    texture = child.material.map; // Store the texture from the model
                }
            });

            // Shader code
            const vertexShader = `
                varying vec4 vClipPosition;
                varying vec3 vNormal;
                varying vec3 vPosition;
                varying vec2 vUv;

                void main() {
                    vClipPosition = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    vNormal = normalize(normalMatrix * normal);
                    vPosition = (modelMatrix * vec4(position,1.0)).xyz;
                    vUv = uv; // Pass UV coordinates
                    gl_Position = vClipPosition;
                }
            `;

            const fragmentShader = `
                uniform float uNavbarClipY;
                uniform vec3 uLightPosition;
                uniform sampler2D uTexture; // Use the model's texture

                varying vec4 vClipPosition;
                varying vec3 vNormal;
                varying vec3 vPosition;
                varying vec2 vUv;

                void main() {
                    float clipY = vClipPosition.y / vClipPosition.w;
                    
                    // Convert uNavbarClipY from screen space (0 to 1) to clip space (-1 to 1)
                    float navbarInClipSpace = 1.0 - 2.0 * uNavbarClipY;
                    
                    if (clipY > navbarInClipSpace) discard;

                    // Base texture color
                    vec3 textureColor = texture2D(uTexture, vUv).rgb;

                    // Diffuse lighting
                    vec3 lightDirection = normalize(uLightPosition - vPosition);
                    float diffuse = max(dot(vNormal, lightDirection), 0.0);
                    vec3 lightColor = vec3(1.0, 1.0, 1.0); // White light
                    
                    // Combine texture with lighting
                    vec3 finalColor = textureColor * diffuse * lightColor; 

                    gl_FragColor = vec4(finalColor, 1.0);
                }
            `;

            // Create Shader Material
            const material = new THREE.ShaderMaterial({
                uniforms: {
                    uNavbarClipY: { value: 0.0 },
                    uLightPosition: { value: new THREE.Vector3(5, 5, 5) },
                    uTexture: { value: texture } // Pass the extracted texture
                },
                vertexShader,
                fragmentShader,
                transparent: true,
            });

            // Apply material while keeping the texture
            model.traverse((child) => {
                if (child.isMesh) {
                    child.material = material;
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            scene.add(model);
            ModelObject.model = model;
        });

        // Add lighting to the scene
        const ambientLight = new THREE.AmbientLight(0x404040, 0.5);
        scene.add(ambientLight);

        return {
            update: ({ delta, mouse, scrollProgress, navbarClipY }) => {
                if (ModelObject.model) {
                    const model = ModelObject.model;

                    const angle = scrollProgress * Math.PI * 2;
                    const radius = 3;
                    const targetX = Math.sin(angle) * radius;
                    const targetY = Math.cos(angle) * radius;

                    model.position.x += (targetX - model.position.x) * 0.05;
                    model.position.y += (targetY - model.position.y) * 0.05;

                    const scale = 0.8 + Math.sin(scrollProgress * Math.PI) * 0.3;
                    const scaleMultiplier = 1.5;
                    const newScale = scale * scaleMultiplier;
                    model.scale.set(newScale, newScale, newScale);

                    model.rotation.x = scrollProgress * Math.PI * 2;
                    model.rotation.y = scrollProgress * Math.PI * 4;

                    // Apply mouse/touch influence with a slightly stronger effect for mobile
                    // This helps compensate for the fact that touch controls tend to feel less precise
                    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
                    const mouseInfluence = isMobile ? 0.3 : 0.2; // Slightly stronger influence on mobile
                    
                    model.position.x += mouse.x * mouseInfluence;
                    model.position.y += mouse.y * mouseInfluence;

                    // Update uniform
                    model.traverse((child) => {
                        if (child.isMesh && child.material.uniforms) {
                            child.material.uniforms.uNavbarClipY.value = navbarClipY;
                        }
                    });
                }
            },
        };
    },

    update: (controller, params) => {
        if (controller && typeof controller.update === 'function') {
            controller.update(params);
        }
    },

    dispose: (controller, scene) => {
        if (controller && controller.model) {
            controller.model.traverse((child) => {
                if (child.isMesh) {
                    if (child.geometry) child.geometry.dispose();
                    if (child.material) child.material.dispose();
                }
            });
            scene.remove(controller.model);
            controller.model = null;
            controller.isLoaded = false;
        }
    }
};

export default ModelObject;