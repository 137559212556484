import * as THREE from 'three';

const SphereObject = {
  create: (scene) => {
    const geometry = new THREE.SphereGeometry(2, 32, 32);
    
    const vertexShader = `
      varying vec3 vNormal;
      varying vec4 vClipPosition;
      varying vec3 vPosition;

      void main() {
        vNormal = normalize(normalMatrix * normal);
        vClipPosition = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vPosition = position;
        gl_Position = vClipPosition;
      }
    `;
    
    const fragmentShader = `
      uniform float uNavbarClipY;
      uniform float uTime;
      uniform float uScrollProgress;
      varying vec3 vNormal;
      varying vec4 vClipPosition;
      varying vec3 vPosition;

      void main() {
        if (vClipPosition.y / vClipPosition.w < uNavbarClipY) discard;

        float intensity = pow(0.7 - dot(vNormal, vec3(0, 0, 1.0)), 2.0);
        float pulse = sin(uTime * 2.0 + uScrollProgress * 15.0) * 0.5 + 0.5;

        vec3 baseColor = mix(vec3(0.2, 0.6, 1.0), vec3(1.0, 0.1, 0.5), uScrollProgress);
        vec3 finalColor = mix(baseColor, vec3(1.0), pulse * 0.3);

        gl_FragColor = vec4(finalColor, 1.0) * intensity * 1.5;
      }
    `;

    const material = new THREE.ShaderMaterial({
      uniforms: {
        uNavbarClipY: { value: 0.0 },
        uTime: { value: 0 },
        uScrollProgress: { value: 0 }
      },
      vertexShader,
      fragmentShader,
      transparent: true,
      blending: THREE.AdditiveBlending
    });

    const sphereMesh = new THREE.Mesh(geometry, material);
    sphereMesh.position.set(0, 0, 1);
    scene.add(sphereMesh);

    return sphereMesh;
  },

  update: (sphere, { delta, mouse, scrollProgress }) => {
    if (sphere && sphere.material.uniforms) {
      // Update uniforms for animation and clipping
      sphere.material.uniforms.uTime.value += delta;
      sphere.material.uniforms.uScrollProgress.value = scrollProgress;

      // Get navbar position in **screen space** and update clipping
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        const navbarY = navbar.getBoundingClientRect().bottom;
        const navbarClipY = 1 - (navbarY / window.innerHeight) * 2;
        sphere.material.uniforms.uNavbarClipY.value = navbarClipY;
      }

      // Define movement path based on scroll progress (0 to 1)
      const angle = scrollProgress * Math.PI * 2;
      const radius = 3;
      const targetX = Math.sin(angle) * radius;
      const targetY = Math.cos(angle) * radius;

      // Smoothly animate position
      sphere.position.x += (targetX - sphere.position.x) * 0.05;
      sphere.position.y += (targetY - sphere.position.y) * 0.05;

      // Scale dynamically based on scroll (slightly bigger in the middle)
      const scale = 0.8 + Math.sin(scrollProgress * Math.PI) * 0.3;
      sphere.scale.set(scale, scale, scale);

      // Rotation effect
      sphere.rotation.x = scrollProgress * Math.PI * 2;
      sphere.rotation.y = scrollProgress * Math.PI * 4;

      // Add slight mouse movement influence
      sphere.position.x += mouse.x * 0.2;
      sphere.position.y += mouse.y * 0.2;
    }
  },

  dispose: (sphere, scene) => {
    if (!sphere) return;

    sphere.geometry.dispose();
    sphere.material.dispose();
    scene.remove(sphere);
  }
};

export default SphereObject;