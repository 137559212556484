import React from "react";
import { Github, ExternalLink, Calendar, Hourglass } from "lucide-react";
import "./ProjectShowcase.css";

const projects = [
  {
    title: "Legacy Website 2021",
    description:
      "My first concept for my portfolio website. I wanted to make something to showcase my Unity skills so I made a lobby, a slot machine game, and a boggle-like game.",
    image: "/images/LegacyLegacyPreview.png",
    link: "http://gatorgo.web.app",
    github: "",
    date: "2021",
    tags: ["C#", "Unity", "WebGL", "Website"],
  },
  {
    title: "Prize Kingdoms",
    description:
      "The first mobile game I worked on professionally, Prize Kingdoms blends board game mechanics with slot machine elements, offering players a chance to win real-world prizes through a sweepstakes system.",
    image: "/images/PrizeKingdomsPreview.png",
    link: "https://play.google.com/store/apps/details?id=com.inkgames.prizekingdoms&hl=en_US&pli=1",
    github: "",
    date: "2021-2023",
    tags: ["C#", "Unity", "Mobile", "iOS", "Android"],
  },
  {
    title: "Legacy Website 2024",
    description:
      "My second concept for my portfolio website. I intended to create something unique that required user interaction. Ultimately, I decided to make something more traditional, so this is now a legacy website.",
    image: "/images/LegacyPreview.png",
    link: "https://spacecomplexity.itch.io/legacywebsite2024",
    github: "",
    date: "2024",
    tags: ["C#", "Unity", "WebGL", "Website"],
  },
  {
    title: "Match Three Demo",
    description:
      "A match three feature I was invited to create with the constraint of only using basic Unity features.",
    image: "/images/MatchThreePreview.png",
    link: "https://spacecomplexity.itch.io/match-three-demo",
    github: "",
    date: "2024",
    tags: ["C#", "Unity", "Match Three", "Slot Machine"],
  },
  {
    title: "Horizon Run",
    description: "An endless runner with roguelike elements intended for mobile.",
    image: "/images/HorizonRunPreview.png",
    link: "",
    github: "",
    date: "2025",
    tags: ["C#", "Unity", "Mobile"],
  },
];

const ProjectShowcase = () => {
  return (
    <div className="project-showcase">
      <h2 className="section-title">Portfolio</h2>
      <div className="projects-container">
        {projects.map(({ title, description, image, link, github, date, tags }, index) => (
          <div key={index} className="hologram-card">
            <div className="hologram-overlay"></div>
            <div className="project-content">
              <img src={image} alt={title} className="project-image" />
              <h3>{title}</h3>
              <p>{description}</p>

              <div className="metadata">
                <span>
                  <Calendar size={18} /> {date}
                </span>
              </div>

              <div className="project-links">
                {link && (
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <ExternalLink size={24} /> Link
                  </a>
                )}

                {github && (
                  <a href={github} target="_blank" rel="noopener noreferrer">
                    <Github size={24} /> GitHub
                  </a>
                )}

                {!link && !github && (
                  <span className="work-in-progress">
                    <Hourglass size={24} /> Work in Progress
                  </span>
                )}
              </div>

              <div className="tags">
                {tags.map((tag, idx) => (
                  <span key={idx} className="tag">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectShowcase;
