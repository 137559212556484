import * as THREE from 'three';

const ConnectionManager = {
  navbarHeight: 0,
  
  updateNavbarHeight: () => {
    const navbar = document.querySelector('.navbar');
    ConnectionManager.navbarHeight = navbar ? navbar.offsetHeight : 60; // Simplified with ternary
  },
  
  getAnimatedPosition: (originalPos, time) => {
    return new THREE.Vector3(
      originalPos.x + Math.sin(time * 0.5 + originalPos.z * 2.0) * 0.3,
      originalPos.y + Math.cos(time * 0.3 + originalPos.x * 1.5) * 0.3,
      originalPos.z
    );
  },

  // Cache for viewport calculations
  getViewportData: () => {
    const windowHeight = window.innerHeight;
    ConnectionManager.updateNavbarHeight();
    const navbarPosition = windowHeight - ConnectionManager.navbarHeight;
    return {
      windowHeight,
      navbarPosition,
      navbarClipY: 1 - (navbarPosition / windowHeight) * 2 // Pre-calculate clip Y
    };
  },

  // Convert screen Y coordinate to clip space
  toScreenY: (y, windowHeight) => ((1 - y) / 2) * windowHeight,

  recalculateConnections: (particles) => {
    // Get the latest viewport data
    const { windowHeight, navbarPosition } = ConnectionManager.getViewportData();
    
    // Store old connections for transitions
    const oldConnections = particles.activeConnections || [];
    const oldConnectionsMap = {};
    
    // Create map of old connections for quick lookup
    for (let i = 0; i < oldConnections.length; i += 2) {
      const key = `${oldConnections[i]}-${oldConnections[i+1]}`;
      oldConnectionsMap[key] = { 
        index1: oldConnections[i], 
        index2: oldConnections[i+1],
        opacity: particles.connectionOpacities ? particles.connectionOpacities[i/2] || 0.2 : 0.2
      };
    }
    
    const newConnections = [];
    const newConnectionsMap = {};
    const newOpacities = [];
    
    const positions = particles.geometry.attributes.position.array;
    const time = particles.material.uniforms.time.value;
    
    // Pre-calculate all animated positions (improves performance)
    const animatedPositions = [];
    const particleCount = positions.length / 3;
    for (let i = 0; i < particleCount; i++) {
      const baseIndex = i * 3;
      const originalPos = new THREE.Vector3(
        positions[baseIndex],
        positions[baseIndex + 1],
        positions[baseIndex + 2]
      );
      animatedPositions.push(ConnectionManager.getAnimatedPosition(originalPos, time));
    }

    // Project all positions to screen coordinates once (reduces redundant calculations)
    const screenPositions = [];
    for (let i = 0; i < animatedPositions.length; i++) {
      const vector = animatedPositions[i].clone();
      vector.project(particles.camera);
      const screenY = ConnectionManager.toScreenY(vector.y, windowHeight);
      screenPositions.push({
        vector, 
        screenY, 
        isAboveNavbar: screenY < navbarPosition
      });
    }

    // Check for connections with reduced redundant calculations
    for (let i = 0; i < animatedPositions.length; i++) {
      // Only process particles above navbar
      if (!screenPositions[i].isAboveNavbar) continue;
      
      // Random sampling to reduce connections (performance optimization)
      if (Math.random() >= 0.8) continue; // Skip 20% of particles
      
      let connectionsForThisParticle = 0;
      
      for (let j = i + 1; j < animatedPositions.length; j++) {
        if (connectionsForThisParticle >= particles.maxConnections) break;
        
        // Quick check if second particle is above navbar
        if (!screenPositions[j].isAboveNavbar) continue;
        
        const distance = animatedPositions[i].distanceTo(animatedPositions[j]);
        const effectiveDistance = particles.connectionDistance * (0.8 + Math.random() * 0.4);
        
        if (distance < effectiveDistance) {
          const key = `${i}-${j}`;
          
          newConnections.push(i, j);
          
          // Handle connection opacity transition
          newOpacities.push(oldConnectionsMap[key] ? 
                           oldConnectionsMap[key].opacity : 
                           0.01); // Start new connections with low opacity
          
          newConnectionsMap[key] = true;
          connectionsForThisParticle++;
        }
      }
    }
    
    // Add fading connections from previous frame
    for (const key in oldConnectionsMap) {
      if (!newConnectionsMap[key] && oldConnectionsMap[key].opacity > 0.01) {
        const conn = oldConnectionsMap[key];
        newConnections.push(conn.index1, conn.index2);
        // Gradually reduce opacity for smooth fade out
        newOpacities.push(conn.opacity * 0.8);
      }
    }
    
    // Store connections and their opacities
    particles.activeConnections = newConnections;
    particles.connectionOpacities = newOpacities;
    particles.animatedPositions = animatedPositions;
    
    ConnectionManager.updateLineGeometry(particles, newConnections, newOpacities);
  },

  updateLineGeometry: (particles, connections, opacities) => {
    if (connections.length === 0) return; // Early return optimization
    
    const animatedPositions = particles.animatedPositions;
    const lineCount = connections.length / 2;
    
    // Optimize array creation with typed arrays of exact size
    const linePositions = new Float32Array(connections.length * 3);
    const lineColors = new Float32Array(connections.length * 4);
    
    // Get the viewport data for clipping
    const { windowHeight, navbarPosition, navbarClipY } = ConnectionManager.getViewportData();

    // Calculate positions and colors for each line    
    for (let i = 0, posIndex = 0, colorIndex = 0; i < connections.length; i += 2) {
      const idx1 = connections[i];
      const idx2 = connections[i + 1];
      
      // Get particle positions
      const pos1 = animatedPositions[idx1];
      const pos2 = animatedPositions[idx2];
      
      // Project to get screen coordinates
      const vector1 = pos1.clone().project(particles.camera);
      const vector2 = pos2.clone().project(particles.camera);
      
      // Calculate screen Y positions
      const screenY1 = ConnectionManager.toScreenY(vector1.y, windowHeight);
      const screenY2 = ConnectionManager.toScreenY(vector2.y, windowHeight);
      
      // Handle different clipping cases
      if (screenY1 < navbarPosition && screenY2 < navbarPosition) {
        // Both points are above navbar, use them as-is
        linePositions[posIndex++] = pos1.x;
        linePositions[posIndex++] = pos1.y;
        linePositions[posIndex++] = pos1.z;
        
        linePositions[posIndex++] = pos2.x;
        linePositions[posIndex++] = pos2.y;
        linePositions[posIndex++] = pos2.z;
      } 
      else if (screenY1 < navbarPosition && screenY2 >= navbarPosition) {
        // First point above, second below - clip at navbar
        linePositions[posIndex++] = pos1.x;
        linePositions[posIndex++] = pos1.y;
        linePositions[posIndex++] = pos1.z;
        
        // Interpolate to find intersection with navbar
        const t = (navbarClipY - vector2.y) / (vector1.y - vector2.y);
        
        // Optimized linear interpolation
        linePositions[posIndex++] = pos2.x + t * (pos1.x - pos2.x);
        linePositions[posIndex++] = pos2.y + t * (pos1.y - pos2.y);
        linePositions[posIndex++] = pos2.z + t * (pos1.z - pos2.z);
      } 
      else if (screenY2 < navbarPosition && screenY1 >= navbarPosition) {
        // Second point above, first below - clip at navbar
        const t = (navbarClipY - vector1.y) / (vector2.y - vector1.y);
        
        // Optimized linear interpolation
        linePositions[posIndex++] = pos1.x + t * (pos2.x - pos1.x);
        linePositions[posIndex++] = pos1.y + t * (pos2.y - pos1.y);
        linePositions[posIndex++] = pos1.z + t * (pos2.z - pos1.z);
        
        linePositions[posIndex++] = pos2.x;
        linePositions[posIndex++] = pos2.y;
        linePositions[posIndex++] = pos2.z;
      } 
      else {
        // Both points below navbar - hide by moving far away
        linePositions[posIndex++] = 0;
        linePositions[posIndex++] = 1000;
        linePositions[posIndex++] = 0;
        
        linePositions[posIndex++] = 0;
        linePositions[posIndex++] = 1000;
        linePositions[posIndex++] = 0;
      }
      
      // Set colors with opacity (optimize with direct index calculation)
      const opacity = opacities[i/2];
      
      // First point RGBA
      lineColors[colorIndex++] = 1.0;
      lineColors[colorIndex++] = 1.0;
      lineColors[colorIndex++] = 1.0;
      lineColors[colorIndex++] = opacity;
      
      // Second point RGBA
      lineColors[colorIndex++] = 1.0;
      lineColors[colorIndex++] = 1.0;
      lineColors[colorIndex++] = 1.0;
      lineColors[colorIndex++] = opacity;
    }

    // Update the line geometry
    particles.lineGeometry.setAttribute('position', new THREE.BufferAttribute(linePositions, 3));
    particles.lineGeometry.setAttribute('color', new THREE.BufferAttribute(lineColors, 4));
    particles.lineGeometry.computeBoundingSphere();
    particles.lineGeometry.setDrawRange(0, connections.length);
    particles.lineGeometry.attributes.position.needsUpdate = true;
    particles.lineGeometry.attributes.color.needsUpdate = true;
  },

  updateLineOpacity: (particles, delta) => {
    if (!particles.connectionOpacities || !particles.activeConnections || 
        particles.connectionOpacities.length === 0) return;
    
    let needsUpdate = false;
    particles.connectionTargets = particles.connectionTargets || [];
    
    const fadeSpeed = 0.2 * delta;
    const opacityCount = particles.connectionOpacities.length;
    
    // Update opacities for all connections
    for (let i = 0; i < opacityCount; i++) {
      // Randomly change fade direction (with reduced randomness checks)
      if (Math.random() < 0.02) { // 2% chance per frame
        particles.connectionTargets[i] = Math.random() < 0.5 ? 0 : 0.3;
      }
      
      // If we have a target, move towards it
      if (particles.connectionTargets[i] !== undefined) {
        const currentOpacity = particles.connectionOpacities[i];
        const target = particles.connectionTargets[i];
        
        if (Math.abs(currentOpacity - target) < fadeSpeed) {
          // At target - set exactly and clear
          particles.connectionOpacities[i] = target;
          particles.connectionTargets[i] = undefined;
        } else if (currentOpacity < target) {
          // Fade in
          particles.connectionOpacities[i] += fadeSpeed;
          needsUpdate = true;
        } else if (currentOpacity > target) {
          // Fade out
          particles.connectionOpacities[i] -= fadeSpeed;
          needsUpdate = true;
        }
      } else if (Math.random() < 0.05) { // 5% chance to get new target
        // 60% chance to fade in, 40% to fade out
        particles.connectionTargets[i] = Math.random() < 0.6 ? 0.3 : 0;
      }
    }
    
    // Update animated positions for moving particles
    const time = particles.material.uniforms.time.value;
    const posArray = particles.geometry.attributes.position.array;
    
    // Update animated positions (batched for better performance)
    if (particles.animatedPositions && particles.animatedPositions.length > 0) {
      for (let i = 0; i < particles.animatedPositions.length; i++) {
        const idx = i * 3;
        const originalPos = new THREE.Vector3(
          posArray[idx],
          posArray[idx + 1],
          posArray[idx + 2]
        );
        particles.animatedPositions[i] = ConnectionManager.getAnimatedPosition(originalPos, time);
      }
      
      // Only update line geometry if we have connections
      if (particles.activeConnections.length > 0) {
        ConnectionManager.updateLineGeometry(
          particles, 
          particles.activeConnections, 
          particles.connectionOpacities
        );
      }
    }
  },
  
  // Mouse interaction handling
  setMousePosition: (x, y, camera, particles) => {
    // Get viewport data
    const { windowHeight, navbarPosition } = ConnectionManager.getViewportData();
    
    // Hide cursor particle when below navbar
    if (y >= navbarPosition) {
      if (particles.cursorParticle) {
        particles.cursorParticle.visible = false;
        particles.cursorLine.visible = false;
      }
      return;
    }
    
    // Show cursor elements
    if (particles.cursorParticle) {
      particles.cursorParticle.visible = true;
      particles.cursorLine.visible = true;
    }
    
    // Convert mouse position to normalized device coordinates
    const mouseX = (x / window.innerWidth) * 2 - 1;
    const mouseY = -(y / window.innerHeight) * 2 + 1;
    
    // Use raycaster to get 3D cursor position
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(new THREE.Vector2(mouseX, mouseY), camera);
    
    // Calculate point in 3D space
    const cursorPos = new THREE.Vector3();
    raycaster.ray.at(10, cursorPos); // 10 units from camera
    
    // Update cursor position
    if (particles.cursorParticle) {
      particles.cursorParticle.position.copy(cursorPos);
    }
    
    // Update cursor connections
    ConnectionManager.updateCursorConnections(particles, cursorPos);
  },
  
  updateCursorConnections: (particles, cursorPos) => {
    if (!particles.animatedPositions || !particles.cursorLine) return;
    
    // Get viewport data for clipping
    const { windowHeight, navbarPosition } = ConnectionManager.getViewportData();
    
    // Prepare arrays for line data (use typed arrays for better performance)
    const maxCursorConnections = Math.min(3, particles.animatedPositions.length);
    const maxLinePoints = maxCursorConnections * 2;
    const linePositions = new Float32Array(maxLinePoints * 3);
    const lineColors = new Float32Array(maxLinePoints * 4);
    
    // Find closest visible particles to cursor
    const connections = [];
    
    for (let i = 0; i < particles.animatedPositions.length; i++) {
      const pos = particles.animatedPositions[i];
      
      // Check if particle is above navbar
      const vector = pos.clone().project(particles.camera);
      const screenY = ConnectionManager.toScreenY(vector.y, windowHeight);
      
      if (screenY < navbarPosition) {
        const distance = cursorPos.distanceTo(pos);
        // Only consider particles within possible connection range
        if (distance < particles.connectionDistance * 1.5) {
          connections.push({ index: i, position: pos, distance });
        }
      }
    }
    
    // Sort by distance and keep closest only
    connections.sort((a, b) => a.distance - b.distance);
    const closestConnections = connections.slice(0, maxCursorConnections);
    
    // Create line positions and colors
    let posIndex = 0;
    let colorIndex = 0;
    
    for (const connection of closestConnections) {
      // Calculate line opacity based on distance
      const opacity = Math.max(0, 1 - connection.distance / (particles.connectionDistance * 1.5)) * 0.6;
      
      // Add cursor position
      linePositions[posIndex++] = cursorPos.x;
      linePositions[posIndex++] = cursorPos.y;
      linePositions[posIndex++] = cursorPos.z;
      
      // Add color for cursor point
      lineColors[colorIndex++] = 1.0; // R
      lineColors[colorIndex++] = 1.0; // G
      lineColors[colorIndex++] = 1.0; // B
      lineColors[colorIndex++] = opacity; // A
      
      // Add particle position
      linePositions[posIndex++] = connection.position.x;
      linePositions[posIndex++] = connection.position.y;
      linePositions[posIndex++] = connection.position.z;
      
      // Add color for particle point
      lineColors[colorIndex++] = 1.0; // R
      lineColors[colorIndex++] = 1.0; // G
      lineColors[colorIndex++] = 1.0; // B
      lineColors[colorIndex++] = opacity; // A
    }
    
    // Update cursor line geometry
    const lineGeometry = particles.cursorLine.geometry;
    lineGeometry.setAttribute('position', new THREE.Float32BufferAttribute(linePositions, 3));
    lineGeometry.setAttribute('color', new THREE.Float32BufferAttribute(lineColors, 4));
    lineGeometry.computeBoundingSphere();
    lineGeometry.setDrawRange(0, posIndex / 3);
    lineGeometry.attributes.position.needsUpdate = true;
    lineGeometry.attributes.color.needsUpdate = true;
  },
  
  disableMouseConnections: (particles) => {
    // Hide cursor-related elements when mouse leaves
    if (particles && particles.cursorParticle) {
      particles.cursorParticle.visible = false;
    }
    if (particles && particles.cursorLine) {
      particles.cursorLine.visible = false;
    }
  }
};

export default ConnectionManager;