import * as THREE from 'three';
const LineMaterial = {
  create: () => {
    return new THREE.LineBasicMaterial({
      vertexColors: true,
      transparent: true,
      opacity: 1.0,
      blending: THREE.AdditiveBlending,
      depthWrite: false
    });
  }
};
export default LineMaterial;

// THE CLIPPING FEATURE
// import * as THREE from 'three';
// const LineMaterial = {
//   create: () => {
//     return new THREE.ShaderMaterial({
//       uniforms: {
//         uNavbarClipY: { value: 0.0 }, // This uniform will be updated to clip below navbar
//       },
//       vertexShader: `
//         varying vec4 vClipPosition;
//         void main() {
//           vClipPosition = projectionMatrix * modelViewMatrix * vec4(position, 1.0); 
//           gl_Position = vClipPosition; // Standard 3D to 2D projection
//         }
//       `,
//       fragmentShader: `
//         uniform float uNavbarClipY;
//         varying vec4 vClipPosition;

//         void main() {
//           float clipY = vClipPosition.y / vClipPosition.w; // Convert to clip space Y (-1 to +1)

//           // If the Y position is below the navbar, discard the fragment
//           if (clipY < uNavbarClipY) discard;

//           gl_FragColor = vec4(1.0, 1.0, 1.0, 0.8); // Default white color with opacity
//         }
//       `,
//       transparent: true,
//       blending: THREE.AdditiveBlending,
//       depthWrite: false, // Prevent depth writing, so lines remain visible on top of particles
//     });
//   }
// };
// export default LineMaterial;