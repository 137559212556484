import React, { memo } from 'react';
import './ContentSections.css';

const ContentSections = memo(({ sections = [] }) => (
  <div className="content-container">
    {sections.map(({ id, content }) => (
      <section key={id} id={id} className="content-section">
        {content}
      </section>
    ))}
  </div>
));

export default ContentSections;