import * as THREE from 'three';

const ParticleMaterial = {
  create: () => {
    return new THREE.ShaderMaterial({
      uniforms: { time: { value: 0 } },
      vertexShader: `
        attribute float size;
        attribute vec3 color;
        varying vec3 vColor;
        uniform float time;

        void main() {
          vColor = color;
          vec3 pos = position;
          pos.x += sin(time * 0.5 + position.z * 2.0) * 0.3;
          pos.y += cos(time * 0.3 + position.x * 1.5) * 0.3;
          vec4 mvPosition = modelViewMatrix * vec4(pos, 1.0);
          gl_PointSize = size * (300.0 / -mvPosition.z); // Dynamic size based on depth
          gl_Position = projectionMatrix * mvPosition;
        }
      `,
      fragmentShader: `
        varying vec3 vColor;

        void main() {
          float distance = length(gl_PointCoord - vec2(0.5, 0.5));
          if (distance > 0.5) discard;
          float alpha = 0.8 * smoothstep(0.5, 0.0, distance);
          gl_FragColor = vec4(vColor, alpha);
        }
      `,
      transparent: true,
      depthWrite: false,
      blending: THREE.AdditiveBlending
    });
  }
};

export default ParticleMaterial;
